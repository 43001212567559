import WeatherModule from './weather_module'
import Carousel from '../../../../lib/client_side_plugins/carousel'

const WEATHER_XHR_URL = '/nex/modules/weather/v1?isXHR=true&m_mode=json'
const dispatchCustomEvent = (eventName, data) => {
  const customEvent = document.createEvent('CustomEvent')
  customEvent.initCustomEvent(eventName, false, false, data)
  document.dispatchEvent(customEvent)
}

export default (() => {
  class WeatherController {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]
      if (!this.elems && this.elems > 1) {
        return
      }
      this.init()
    }

    init() {
      this.instances = this.elems.map(elem => new WeatherModule(elem, this))
      this.carousels = this.elems
        .map(elem => {
          const carouselElm = elem.querySelector('.m-weather__content .m-cb__carousel--xs-only')
          if (carouselElm) {
            return new Carousel(carouselElm, this)
          }
          return null
        })
        .filter(Boolean)
      const setCookieInstance = this.instances.find(instance => !!instance.setCookieWoeid)
      if (setCookieInstance) {
        this.setCookie(setCookieInstance.setCookieWoeid)
      }
      this.addEventListeners()
    }

    addEventListeners() {
      // Listen for sync weather sync event. Currently only sent from amp
      document.addEventListener('sync:weather', ({ detail = {} }) => {
        const { woeid, zip = '0' } = detail
        if (woeid && zip) {
          // only need to fetch on one instance as the rest will sync
          this.instances[0].fetch({ woeid, zip })
        }
      })
    }

    sync(data, rerender, isNewLocation) {
      this._syncWeatherModules(data, rerender, isNewLocation)
      this._syncNews(data)
      this._syncLocationChange(data)
    }

    // PRIVATE METHODS
    _syncWeatherModules({ response = {} }, rerender, isNewLocation) {
      this.instances.forEach(module => {
        const { urlPrefix } = module
        module.updateUI({ ...response, urlPrefix }, rerender, isNewLocation)
      })
    }

    _syncNews({ response = {}, metadata = {} }) {
      let { location: { woeid } = {} } = response
      woeid = `${woeid}` // woeid needs to be a string for validation
      const zip = this._getZipFromCookieMetadata(metadata)
      dispatchCustomEvent('sync:news', { woeid, zip })
    }

    _syncLocationChange({ response = {}, metadata = {} }) {
      const { location = {} } = response
      const zip = this._getZipFromCookieMetadata(metadata)
      const state = this._getStateFromCookieMetadata(metadata)
      const address = {
        centroid_latitude: location.centroid_latitude,
        centroid_longitude: location.centroid_longitude,
        country_code: location.country_code,
        country: location.country_name,
        display_name: `${location.display_name}, ${location.state}`,
        full_display_name: location.full_display_name,
        latitude: location.centroid_latitude,
        locality: location.display_name,
        longitude: location.centroid_longitude,
        admin_district: location.admin2_name,
        woeid: location.woeid,
        state,
        zip
      }
      dispatchCustomEvent('sync:locationChange', address)
    }

    _getStateFromCookieMetadata({ cookies = [] }) {
      const [cookieData = {}] = cookies
      const { value: { adminDistrict = '' } = {} } = cookieData
      return adminDistrict
    }

    _getZipFromCookieMetadata({ cookies = [] }) {
      // Location api response does not contain zip info.
      // must extract this from value getting set as the cookie
      const [cookieData = {}] = cookies
      const { value: { zip = '0' } = {} } = cookieData
      return zip
    }

    setCookie(woeid) {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ woeid })
      }
      fetch(WEATHER_XHR_URL, options).catch(err =>
        console.error('Error setting weather cookie', err)
      )
    }
  }

  return WeatherController
})()
