import Location from './location'
import { rapidReady } from '../../../../lib/utils/rapid'

const LOCAL_STORAGE_LOCATION_KEY = 'location-list'

export default (() => {
  class LocationController {
    constructor(elem) {
      this.elem = elem
      this.persistantStorage = this.getLocalStorage()
      this.locations = []
      this.isEditMode = false
      const { queryWoeid } = this.elem.dataset
      const currentWoeid = this.getCurrentCookieWoeid()
      if (this.persistantStorage && this.persistantStorage.length) {
        this.persistantStorage.forEach(location => {
          let currentHomeLoc = false
          let currentActiveLoc = false
          if (!Number(currentWoeid) && location.home === true && !queryWoeid) {
            currentHomeLoc = true
            currentActiveLoc = true
          }
          if (queryWoeid && Number(location.woeid) === Number(queryWoeid)) currentActiveLoc = true
          if (currentWoeid && Number(location.woeid) === Number(currentWoeid)) currentHomeLoc = true
          location.isHome = currentHomeLoc
          location.isActive = currentActiveLoc
          this.locations.push(new Location({ ...location }))
        })
      }
    }

    addNewLocation(response, isNewLocation) {
      const { location } = response

      if (!this.locations.length) {
        this.locations.push(new Location({ ...location, isActive: true, isHome: true }))
      } else if (!this.locations.find(loc => Number(loc.getWoeid) === Number(location.woeid))) {
        this.locations.forEach(v => {
          v.setInactive()
          if (v.isHome && this.weatherModule?.isChangeHome) {
            const woeid = Number(v.getWoeid)
            v.removeHomeLocation()
            v.pill.remove()
            this.locations = this.locations.filter(loc => Number(loc.getWoeid) !== woeid)
            this.currentActive = woeid
          }
        })
        this.locations.push(
          new Location({
            ...location,
            isActive: true,
            isHome: !!this.weatherModule?.isChangeHome
          })
        )
      } else if (isNewLocation) {
        // this is to handle when user selects location that is already present
        this.locations.forEach(v => {
          if (v.getWoeid === location.woeid) {
            v.setActive()
            if (this.weatherModule?.isChangeHome) {
              v.setHomeLocation()
            }
          } else {
            v.setInactive()
            if (v.isHome && this.weatherModule.isChangeHome) {
              v.removeHomeLocation()
            }
          }
        })
      }
      this.syncStorage()
    }

    handleServerSidePill(container, weatherModule) {
      if (!container) return
      const pill = container.querySelector('.location-pill.active')
      if (pill) {
        const btnLabel = pill.querySelector('.default-btn__label')
        const location = {
          isActive: true,
          isHome: true,
          display_name: btnLabel.innerText.split(',')[0],
          state: btnLabel.innerText.split(',')[1],
          woeid: pill.dataset.key
        }

        this.locations = this.locations.filter(v => Number(v.getWoeid) !== Number(location.woeid))

        this.locations.push(new Location(location, pill))

        // environment related edge case
        if (this.locations.length > 5) {
          while (this.locations.length > 5) {
            const index = this.locations.findLastIndex(v => !v.isHome && !v.isActive)
            this.locations.splice(index, 1)
          }
        }
      }
    }

    renderLocationPills(container, weatherModule) {
      if (!container) return
      this.weatherModule = weatherModule
      if (this.locations && this.locations.length) {
        const locationContainer = container.querySelector('.locations')
        this.locations.forEach(locationObj => {
          const locationPill = container.querySelector(`[data-key='${locationObj.getWoeid}']`)
          if (this.weatherModule.isMobileView && locationObj.getWoeid === this.currentActive) {
            locationObj.setActive()
            this.currentActive = -1
          }
          if (!locationPill) {
            locationContainer.appendChild(locationObj.pill)
            if (locationObj.pill.getAttribute('listener') !== 'true') {
              this.addEventListeners(locationObj)
            }
          } else if (locationObj.isHome) {
            // need to replace the server side pill, as it's instance is not same as what we have
            // in the serialized storage, plus it does not have click listeners
            locationPill.replaceWith(locationObj.pill)
            if (locationObj.pill.getAttribute('listener') !== 'true') {
              this.addEventListeners(locationObj)
            }
          }

          if (locationObj.hasCrossBtn) {
            locationObj.hideCrossBtn()
            locationObj.removeCrossBtnClass()
          }
        })
      } else if (this.weatherModule.hasCookie) {
        // there could be cookie set by default; this is where we handle that situation
        this.weatherModule.fetch({
          woeid: container.querySelector('.default-btn.primary').dataset.key
        })
      }
      this.isEditMode = false
      this.sortLocationPills(container)
    }

    renderMobilePills(container) {
      if (!container) return
      if (this.locations && this.locations.length) {
        const locationsContainer = container.querySelector('.locations')
        locationsContainer.innerHTML = ''
        this.locations
          .filter(v => v.isHome === false)
          .forEach(locationObj => {
            if (locationObj.isActive) this.currentActive = locationObj.getWoeid
            locationObj.showCrossBtn()
            locationObj.setInactive()
            locationObj.pill.classList.add('hasCrossBtn')
            locationsContainer.appendChild(locationObj.pill)
          })
      }
      this.isEditMode = true
    }

    addEventListeners(locationObj) {
      const { pill } = locationObj
      pill.addEventListener('click', () => {
        const ylk = {
          ...this.weatherModule.ylk,
          slk: locationObj.location.display_name,
          elmt: this.isEditMode ? 'remove-city' : 'saved-city',
          itc: 1,
          elm: 'location',
          sec: 'weather'
        }

        rapidReady(rapid => {
          rapid.beaconClick(ylk.sec, ylk.slk, ylk.itc, ylk)
        })

        pill.setAttribute('listener', 'true')
        if (!this.isEditMode) {
          // set the pill as active
          this.locations.forEach(location => {
            location.setInactive()
          })
          locationObj.setActive()

          if (this.weatherModule.isChangeHome) {
            // change home location
            this.locations.forEach(v => v.removeHomeLocation())
            locationObj.setHomeLocation()
            this.weatherModule.fetch({ woeid: pill.dataset.key, noCookie: '' }, true)
            this.weatherModule.isChangeHome = false
          } else {
            this.weatherModule.fetch({ woeid: pill.dataset.key, noCookie: 'true' }, false)
          }
        } else if (this.isEditMode && !locationObj.isHome) {
          if (locationObj.getWoeid === this.currentActive) {
            // special case where you're trying to remove the current active
            this.locations.forEach(loc => {
              if (loc.isHome) {
                loc.setActive()
                // TODO: need to sync weather and new
                this.weatherModule.fetch({ woeid: loc.pill.dataset.key, noCookie: 'true' }, true)
              }
            })
          }
          // remove from UI
          pill.remove()

          // remove from deserialized instance
          this.locations = this.locations.filter(
            location => Number(location.getWoeid) !== Number(pill.dataset.key)
          )

          this.weatherModule.tooltipHandler()
        }
        if (
          this.weatherModule.isMobileView &&
          (this.weatherModule.renderType === 'weather_v2' ||
            this.weatherModule.renderType === 'weather_channel_v2') &&
          this.weatherModule.isMobileModalVisible()
        ) {
          this.syncStorage()
        }
      })
    }

    toggleCrossBtn() {
      this.locations.forEach(location => {
        if (location.isActive) {
          this.currentActive = location.getWoeid
          location.setInactive()
        }
        if (!location.isHome) {
          const crossBtn = location.pill.lastElementChild
          crossBtn?.classList.toggle('display-hidden')
          location.pill.classList.toggle('hasCrossBtn')
        }
      })
      this.isEditMode = !this.isEditMode

      if (!this.isEditMode) {
        this.locations.forEach(location => {
          if (location.getWoeid === this.currentActive) {
            location.setActive()
          }
        })
      }
    }

    sortLocationPills(container) {
      this.locations.sort((x, y) => {
        if (x.isHome) return -1
        if (y.isHome) return 1
        return 0
      })
      const locationsContainer = container.querySelector('.locations')
      locationsContainer.innerHTML = ''
      this.locations.forEach(v => {
        locationsContainer.appendChild(v.pill)
      })
    }

    syncStorage() {
      this.persistantStorage = []
      this.locations.forEach(locationObj => {
        this.persistantStorage.push(locationObj.location)
      })
      this.setLocalStorage(this.persistantStorage)
    }

    setLocalStorage(value) {
      const stringified = JSON.stringify(value)
      const base64Encoded = window.btoa(stringified)
      window.localStorage.setItem(LOCAL_STORAGE_LOCATION_KEY, base64Encoded)
    }

    sanitizeLocalStorage(lsObj) {
      const filterLsObject = lsObj.filter(loc => !!loc.woeid)
      if (filterLsObject.length !== lsObj.length) {
        this.setLocalStorage(filterLsObject)
      }
      return filterLsObject
    }

    getLocalStorage() {
      const lsString = window.localStorage.getItem(LOCAL_STORAGE_LOCATION_KEY)
      let lsObj = null
      if (!lsString) {
        return lsObj
      }

      try {
        const json = window.atob(lsString)
        lsObj = JSON.parse(json)
        lsObj = this.sanitizeLocalStorage(lsObj)
      } catch (e) {
        // failed to read LS, clean up the corrupt memory
        window.localStorage.removeItem(LOCAL_STORAGE_LOCATION_KEY)
      }

      return lsObj
    }

    get isMaxLocation() {
      return this.locations.length >= 5
    }

    fetchCookie(name) {
      const a = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`)
      return a ? a[1] : ''
    }

    getCurrentCookieWoeid() {
      let currentWoeid
      try {
        return decodeURI(this.fetchCookie('weathergeo'))
          .replace(/['"]+/g, '')
          .split('|')
          .splice(-1)[0]
      } catch (e) {
        return currentWoeid
      }
    }
  }
  return LocationController
})()
