import Glide from '@glidejs/glide'
import detailsRenderer from '../../renderers/weather_details'
import detailsMinimalRenderer from '../../renderers/weather_details_minimal'
import detailsBannerRenderer from '../../renderers/weather_details_banner'
import detailsChannelRenderer from '../../renderers/weather_channel'
import weatherHubV2 from '../../renderers/weather_channel_v2'
import weatherDetailsV2 from '../../renderers/weather_details_v2'
import weatherDetailsBottomNavV2 from '../../renderers/weather_details_bottom_nav_v2'
import rightRailV2 from '../../renderers/weather_right_rail'
import suggestionsRenderer from '../../renderers/weather_suggestions'
import minimalLocationRenderer from '../../renderers/weather_minimal_location'
import { rapidReady } from '../../../../lib/utils/rapid'
import LocationController from './location_controller'

const { arrowDisabler, ariaHiddenAddRemove } = require('../../../../lib/glide')

const SELECTOR_FORM = '.location-form'
const SELECTOR_CARD = '.m-weather-card'
const SELECTOR_WEATHER_DETAILS_CONTAINER = '.weather-details__container'
const SELECTOR_LOCATION_CONTAINER = '.location__container'
const SELECTOR_LOCATION_LBL = `${SELECTOR_LOCATION_CONTAINER} .location`
const SELECTOR_LOCATION_TOGGLE_LBL = `${SELECTOR_LOCATION_CONTAINER} .location__search-toggle`
const SELECTOR_CHANGE_HOME_LOCATION_LBL = '.location-form-flex__toggleable__label'
const SELECTOR_LOCATION_TOGGLE = `.location__container--toggleable`
const SELECTOR_LOCATION_TOGGLER = `.location-form-flex__toggleable`
const SELECTOR_LOCATION_WRAPPER = `.location-form-flex__form`
const SELECTOR_LOCATION_FORM_INPUT = '#location_input'
const SELECTOR_SUGGESTIONS_CONTAINER = '.location-form__suggestions'
const SELECTOR_SUGGESTIONS_CONTAINER_HOME = '.location-form__suggestions_home'
const SELECTOR_LOCATION_FORM_HEADLINE = '.location-form-headline'
const SELECTOR_LOCATION_FORM_CONTAINER = '.location-form__container'
const SELECTOR_LOCATION_HEADING = '.location-form-flex__heading'
const SELECTOR_MOBILE_LOCATION_EDIT = '.location-form-flex__gear'
const SELECTOR_MOBILE_LOCATION_MODAL = '.location-form-flex--mobile'
const SELECTOR_LOCATION_DISPLAY_LBL = `${SELECTOR_LOCATION_FORM_CONTAINER} .display-name`
const SELECTOR_LOCATION_ADD_EDIT_BTN = '#addEditBtnID'
const SELECTOR_LOCATION_ADD_EDIT_BTN_DUPE = '#addEditBtnIDDupe'
const SELECTOR_LOCATION_HOME_INPUT = 'location_input_home'
const SELECTOR_LOCATION_HOME_FORM = '.home-location-form'
const SELECTOR_MOBILE_LOCATION_ADD_EDIT_BTN = '#mAddEditBtnID'
const SELECTOR_LOCATION_PILL_LIST = '.location-list'
const SELECTOR_TOOLTIP = '.tooltiptext'
const SELECTOR_TOOLTIP_CLOSE = '.tooltiptext-close'
const SELECTOR_DESCRIPTION = '.description'
const SELECTOR_OBSERVATIONS = '.observations'
const SELECTOR_FORECAST = '.forecast'
const SELECTOR_HOURLY_FORECAST = '.hourlyForecast'
const SELECTOR_DAILY_FORECAST = '.dailyForecast'
const SELECTOR_HOURLY = '.hourly'
const SELECTOR_DAILY = '.daily'
const SELECTOR_TEMP_FORMAT = '.tempFormat'
const SELECTOR_WEATHER_AND_FORECAST = '.weather-details-and-forecast'
const SELECTOR_GLIDE_ARROWS = '.glide__arrows'
const SELECTOR_VIDEO_WRAPPER = '.video-wrapper'
const NAME_INPUT = 'query'
const CLASS_HIDE_FORM = 'location-form--hidden'
const CLASS_SUGGESTION_ITEM = 'location-form__suggestions--item'
const CLASS_WEATHER_LOCATION_TOGGLE = 'weather-location-toggle'
const CLASS_HIDE_HEADLINE = 'hideHeadline'
const CLASS_SHOW_LOCATION = 'showLocation'
const CLASS_HIDDEN = 'display-hidden'
const CLASS_SELECTED = 'selected'
const CLASS_HIDE_VIDEO = 'hideVideo'
const CLASS_UNIT_C = 'weather-unit-c'
const CLASS_UNIT_F = 'weather-unit-f'
const LOCAL_STORAGE_TEMPERATURE_FORMAT = 'temperature-format'
const TOGGLE_BUTTON_CELSIUS = '.celsius'
const TOGGLE_BUTTON_FAHRENHEIT = '.fahrenheit'
const SUGGESTIONS_DEBOUNC_TIMING = 150
const newWeatherRenderTypes = ['right-rail', 'weather_v2', 'weather_channel_v2']

const CAROUSEL_CONFIG = {
  type: 'slider',
  rewind: false,
  hoverpause: false,
  bound: true,
  autoplay: false,
  perView: 4,
  gap: 0,
  startAt: 0,
  breakpoints: {
    1680: {
      // 1441-1679
      gap: 10,
      perView: 4
    },
    1440: {
      // 1281-1440
      perView: 3,
      gap: 5
    },
    1280: {
      // 481-1280
      perView: 5,
      peek: { before: 0, after: 0 }
    },
    480: {
      perView: 5,
      peek: { before: 0, after: 30 }
    }
  }
}

const DAILY_FORECAST_CONFIG = {
  perView: 4,
  breakpoints: {
    900: {
      perView: 5
    },
    480: {
      perView: 4,
      peek: { before: 0, after: 30 }
    }
  }
}

const debounce = function(func, wait, immediate) {
  let timeout
  return function(...args) {
    const context = this
    const later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export default (() => {
  class WeatherModule {
    constructor(elem, controller) {
      this.elem = elem
      if (!this.elem) {
        return
      }
      this.form = elem.querySelector(SELECTOR_FORM)
      this.controller = controller
      this.suggestionsURL = this.form && this.form.dataset.suggestionsUrl
      this.urlPrefix = this.elem.dataset.urlPrefix || ''
      this.input =
        (this.form && this.form[NAME_INPUT]) ||
        this.form?.querySelector(SELECTOR_LOCATION_FORM_INPUT)
      this.homeLocationForm = this.elem.querySelector(SELECTOR_LOCATION_HOME_FORM)
      this.homeSearchBtn = this.homeLocationForm?.querySelector('button')
      this.detailsContainer = this.elem.querySelector(SELECTOR_WEATHER_DETAILS_CONTAINER)
      this.locationLbl =
        this.elem.querySelector(SELECTOR_LOCATION_LBL) ||
        this.elem.querySelector(SELECTOR_LOCATION_DISPLAY_LBL)
      this.changeLocLbl = this.elem.querySelector(SELECTOR_CHANGE_HOME_LOCATION_LBL)
      this.locationToggleLbl = this.elem.querySelector(SELECTOR_LOCATION_TOGGLE_LBL)
      this.toggle = this.elem.querySelector(SELECTOR_LOCATION_TOGGLE)
        ? [this.elem.querySelector(SELECTOR_LOCATION_TOGGLE)]
        : [...this.elem.querySelectorAll(SELECTOR_LOCATION_TOGGLER)]
      this.heading = this.elem.querySelector(SELECTOR_LOCATION_HEADING)
      this.locationAddEditBtn = [
        this.elem.querySelector(SELECTOR_LOCATION_ADD_EDIT_BTN),
        this.elem.querySelector(SELECTOR_LOCATION_ADD_EDIT_BTN_DUPE)
      ].filter(v => v !== null)
      this.mlocationAddEditBtn = this.elem.querySelector(SELECTOR_MOBILE_LOCATION_ADD_EDIT_BTN)
      this.locationAddEditBtnLabel = this.locationAddEditBtn
        .map(v => v?.querySelector('.default-btn__label'))
        .filter(v => v !== null || v !== undefined)
      this.locationAddEditBtnSettingLabel = this.locationAddEditBtn
        .map(v => v?.querySelector('.configure_location_settings'))
        .filter(v => v !== null || v !== undefined)
      this.suggestionsContainer = this.elem.querySelector(SELECTOR_SUGGESTIONS_CONTAINER)
      this.homeSuggestionContainer = this.elem.querySelector(SELECTOR_SUGGESTIONS_CONTAINER_HOME)
      this.locationFormHeadline = this.elem.querySelector(SELECTOR_LOCATION_FORM_HEADLINE)
      this.locationFormContainer = this.elem.querySelector(SELECTOR_LOCATION_FORM_CONTAINER)
      this.locationPillListContainer = this.elem.querySelector(
        `${SELECTOR_LOCATION_PILL_LIST}.main`
      )
      this.locationContainer = this.elem.querySelector(SELECTOR_LOCATION_CONTAINER)
      this.locationFormWrapper = this.elem.querySelector(SELECTOR_LOCATION_WRAPPER)
      this.weatherCard = this.elem.querySelector(SELECTOR_CARD)
      this.separator = this.elem.querySelector(`${SELECTOR_CARD}__separator-wrapper`)
      this.bottomNav = this.elem.querySelector(`${SELECTOR_CARD}__bottom-nav`)
      this.weatherAndForecast = this.elem.querySelector(SELECTOR_WEATHER_AND_FORECAST)
      this.videoWrapper = this.elem.querySelector(SELECTOR_VIDEO_WRAPPER)
      this.renderType = this.elem.dataset.renderType
      this.slot = this.elem.dataset.slot
      this.defaultTempUnit = this.elem.dataset.defaultTempUnit || 'f'
      this.ylk = this._getYlk()
      this.setCookieWoeid = this.elem.dataset.setCookie || ''
      this.celsiusBtn = this.elem.querySelector(TOGGLE_BUTTON_CELSIUS)
      this.fahrenheitBtn = this.elem.querySelector(TOGGLE_BUTTON_FAHRENHEIT)
      this.locationController = new LocationController(this.elem)
      this.tooltip = this.elem.querySelector(SELECTOR_TOOLTIP)
      this.tooltipClose = this.elem.querySelector(SELECTOR_TOOLTIP_CLOSE)
      this.mLocationEditBtn = this.elem.querySelector(SELECTOR_MOBILE_LOCATION_EDIT)
      this.mLocationModal = this.elem.querySelector(SELECTOR_MOBILE_LOCATION_MODAL)
      this.mLocationPillContainer = this.mLocationModal?.querySelector(SELECTOR_LOCATION_PILL_LIST)
      this.searchBtn = this.form?.querySelector('button')
      this.mHomeLocationForm = this.homeLocationForm?.firstChild
      this.homeLocationInput = this.mHomeLocationForm?.query_home
      this.isChangeHome = false
      this.enableClickOutside = this.elem.dataset.enableClickOutside === 'true'
      this.homeLocationLbl = this.elem.dataset.homeLocLbl || 'Change home location'
      this.init()
    }

    // INITIALIZATION  METHODS
    init() {
      const temperatureFormat = this.getLocalStorage(LOCAL_STORAGE_TEMPERATURE_FORMAT)
      if (!temperatureFormat) {
        this.setLocalStorage(LOCAL_STORAGE_TEMPERATURE_FORMAT, this.defaultTempUnit)
      }
      this.setTempFormat(
        this.getLocalStorage(LOCAL_STORAGE_TEMPERATURE_FORMAT) || this.defaultTempUnit
      )

      if (this.form) {
        if (this.renderType === 'weather_v2' || this.renderType === 'weather_channel_v2') {
          this.locationController.handleServerSidePill(this.locationPillListContainer, this)
          this._renderLocationPills()
        }
        this.addEventListeners()
      }
      if (this.weatherAndForecast && this.renderType !== 'weather_channel_v2') {
        this.initWeatherChannel()
      }
    }

    initWeatherChannel() {
      this.addWeatherChannelEventListeners()
      this.videoWrapper?.classList.remove(CLASS_HIDE_VIDEO)
    }

    initialiseCarousel(elem, config, selectedForecast) {
      if (this.glide) this.glide.destroy()
      this.glide = new Glide(elem.querySelector(selectedForecast), config)
      if (selectedForecast === SELECTOR_DAILY_FORECAST) {
        this.glide.update(DAILY_FORECAST_CONFIG)
        this.glide.on(
          'resize',
          debounce(e => {
            this.resizeEventHandler()
          })
        )
        this.glide.mount({ arrowDisabler, ariaHiddenAddRemove })
      }
      this.glide.on('run.before', evt => {
        const scrollSteps = this.glide.settings.perView
        if (evt.direction === '>') evt.steps = -scrollSteps
        else if (evt.direction === '<') evt.steps = scrollSteps
      })
      this.glide.mount({ arrowDisabler, ariaHiddenAddRemove })
    }

    addEventListeners(elem) {
      this.toggle.forEach(v =>
        v?.addEventListener('click', e => {
          this.isChangeHome = true
          this.tooltipHandler()
          this.toggleClickHandler(e)
        })
      )
      this.form.addEventListener('submit', e => {
        this.submitHandler(e)
      })

      this.mHomeLocationForm?.addEventListener('submit', e => {
        this.submitHandler(e)
      })
      this.input?.addEventListener(
        'keyup',
        debounce(e => {
          this.inputKeyupHandler(e)
        }, SUGGESTIONS_DEBOUNC_TIMING)
      )

      if (newWeatherRenderTypes.includes(this.renderType)) {
        this.input?.addEventListener('focus', () => {
          this.tooltipHandler()
        })

        this.homeLocationInput?.addEventListener('focus', e => {
          this.homeLocationInput.value = ''
        })
      }

      this.homeLocationInput?.addEventListener(
        'keyup',
        debounce(e => {
          this.isChangeHome = true
          this.inputKeyupHandler(e)
        }, SUGGESTIONS_DEBOUNC_TIMING)
      )
      this.suggestionsContainer?.addEventListener('click', e => {
        this.suggestionClickHandler(e)
      })
      this.suggestionsContainer?.addEventListener('keyup', e => {
        if (e.keyCode === 13) {
          this.suggestionClickHandler(e)
        }
      })
      this.homeSuggestionContainer?.addEventListener('click', e => {
        this.isChangeHome = true
        this.suggestionClickHandler(e)
      })
      this.homeSuggestionContainer?.addEventListener('keyup', e => {
        this.isChangeHome = true
        if (e.keyCode === 13) {
          this.suggestionClickHandler(e)
        }
      })

      document.addEventListener('click', e => {
        this.closeLocationList(e)
        this._suggestionContainerHandler(e)
        this._rightRailHandler(e)
      })

      this.locationAddEditBtn &&
        this.locationAddEditBtn.forEach(v =>
          v?.addEventListener('click', e => {
            this.toggleClickHandler(e)
            if (this.isChangeHome) {
              this.setEditLabel()
              this.isChangeHome = !this.isChangeHome
              return
            }
            this.tooltipHandler()

            this.locationController.toggleCrossBtn()
            this.locationController.syncStorage()
            if (!this.locationController.isEditMode) {
              this.setEditLabel()
              this.showHeading()
              this._hideForm()
              this.showLocationToggleer()
              this.toggleBtnLabel(e)
            } else {
              this.setDoneLabel()
            }
          })
        )

      this.mLocationEditBtn?.addEventListener('click', () => {
        // gear
        this.tooltipHandler()
        this.toggleMobileModal()
        this.locationController.renderMobilePills(this.mLocationPillContainer)
        const home = this.locationController.locations.find(v => v.isHome).location
        this.homeLocationInput.value = `${home?.display_name}, ${home?.state}`
      })

      this.mlocationAddEditBtn?.addEventListener('click', () => {
        this._renderLocationPills()
        this.toggleMobileModal()
      })
      this.tooltipClose?.addEventListener('click', () => {
        this.tooltip.classList.add('display-hidden')
      })
    }

    tooltipHandler() {
      if (newWeatherRenderTypes.includes(this.renderType)) {
        if (this.locationController.isMaxLocation && !this.isChangeHome) {
          // show the tooltip
          this.tooltip?.classList.remove('display-hidden')
          this.searchBtn.disabled = true
          this.searchBtn.classList.add('disabled')
          if (this.input) {
            this.input.disabled = true
          }
        } else {
          this.tooltip?.classList.add('display-hidden')
          if (this.input) {
            this.input.disabled = false
          }
          this.searchBtn.disabled = false
          this.searchBtn.classList.remove('disabled')
        }
      }
    }

    toggleMobileModal() {
      this.mLocationModal.classList.toggle('display-hidden')
      document.body.classList.toggle('weather-picker-open')
    }

    isMobileModalVisible() {
      return !this.mLocationModal.classList.contains('display-hidden')
    }

    toggleBtnLabel(e) {
      if (this.locationController.isEditMode) {
        this.setDoneLabel()
        this.showForm()
      } else {
        this.setEditLabel()
        // this.hideForm()
      }
    }

    setDoneLabel() {
      if (this.locationAddEditBtnLabel && this.renderType !== 'right-rail') {
        this.locationAddEditBtnLabel.forEach(v => {
          v.innerText = 'Done'
          v.classList.remove('invisible')
          v.classList.add('visible')
          if (this.locationAddEditBtnSettingLabel) {
            this.locationAddEditBtnSettingLabel.forEach(x => {
              x.classList.remove('visible')
              x.classList.add('invisible')
            })
          }
        })
        this.locationAddEditBtn.forEach(v => v.classList.add('done'))
      }
    }

    setEditLabel() {
      if (this.suggestionsContainer) {
        this.suggestionsContainer.innerHTML = ''
      }
      if (this.locationAddEditBtnLabel && this.renderType !== 'right-rail') {
        this.locationAddEditBtnLabel.forEach(v => {
          v.innerText = 'Add / Edit'
          v.classList.remove('invisible')
          v.classList.add('visible')
          if (this.locationAddEditBtnSettingLabel) {
            if (window.innerWidth <= 900) {
              v.classList.remove('visible')
              v.classList.add('invisible')
              this.locationAddEditBtnSettingLabel.forEach(x => {
                x.classList.remove('invisible')
                x.classList.add('visible')
              })
            } else {
              this.locationAddEditBtnSettingLabel.forEach(x => {
                x.classList.add('invisible')
                x.classList.remove('visible')
              })
            }
          }
        })
        this.locationAddEditBtn.forEach(v => v.classList.remove('done'))
      }
    }

    showHeading() {
      this.heading.classList.remove(CLASS_HIDDEN)
    }

    showLocationToggleer() {
      this.toggle.forEach(v => v?.classList.remove(CLASS_HIDDEN))
    }

    showForm() {
      this.form.classList.remove(CLASS_HIDDEN)
      this.locationFormWrapper?.classList.remove('w-0')
    }

    closeLocationList(e) {
      const isWeatherChannel = e.target.closest(`#m-weather__channel`)
      if (
        !isWeatherChannel ||
        (this.locationFormHeadline && !!this.locationFormHeadline.offsetWidth)
      ) {
        return
      }

      const classNames = Array.from(e.target.classList).join(',')

      if (classNames.indexOf('location') < 0 || classNames.indexOf('showLocation') > 0) {
        this._hideForm()
      }
    }

    getLocalStorage(key) {
      try {
        return JSON.parse(window.localStorage.getItem(key))
      } catch (e) {
        return null
      }
    }

    setLocalStorage(key, value) {
      window.localStorage.setItem(key, JSON.stringify(value))
    }

    resizeEventHandler() {
      this.glide.update(DAILY_FORECAST_CONFIG)
      this.glide.mount({ arrowDisabler, ariaHiddenAddRemove })
    }

    toggleArrows(arrows) {
      if (window.innerWidth > 900) {
        arrows.classList.add('hideArrows')
      } else {
        arrows.classList.remove('hideArrows')
      }
    }

    // PUBLIC METHODS
    updateUI(response, rerender = true, isNewLocation = false) {
      this._hideForm(response)
      this._renderWeatherDetails({ ...response, slot: this.slot })
      this._updateLocation(response)
      this.locationController.addNewLocation(response, isNewLocation)
      if (
        rerender &&
        (this.renderType === 'weather_v2' || this.renderType === 'weather_channel_v2')
      ) {
        this._renderLocationPills(response)
        if (
          this.isMobileView &&
          (this.renderType === 'weather_v2' || this.renderType === 'weather_channel_v2') &&
          this.isMobileModalVisible()
        ) {
          this.locationController.renderMobilePills(this.mLocationPillContainer)
        }
        this.setEditLabel()
        this.showHeading()
        this.showLocationToggleer()
        this.tooltipHandler()
        if (this.isChangeHome) this.isChangeHome = false
      }
    }

    fetch(body, rerender, isNewLocation) {
      const actionUrl = this.form.action
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
      fetch(actionUrl, options)
        .then(response => response.json())
        .then(({ data = {} }) => {
          const { response: { weather } = {} } = data
          if (!weather) {
            return
          }
          this.controller.sync(data, rerender, isNewLocation)
        })
        .catch(err => console.error(err))
    }

    // EVENT HANDLERS
    submitHandler(e) {
      e.preventDefault()
      const value = this.input?.value || this.homeLocationInput?.value

      if (!value) {
        return
      }
      let isCookie = this.hasCookie
      if (this.isChangeHome) {
        isCookie = false
      }
      const body = {
        query: value
      }

      if (newWeatherRenderTypes.includes(this.renderType)) {
        body.noCookie = !isCookie ? '' : 'true'
      }

      this.fetch(body, true, true)
    }

    inputKeyupHandler(e) {
      const isEsc = e.keyCode === 27
      const isAlphaNumbericKey = e.keyCode >= 48 && e.keyCode <= 90
      const isPunctuation = e.keyCode === 32 || (e.keyCode >= 188 && e.keyCode <= 191)
      const isNumber = e.keyCode >= 96 && e.keyCode <= 105
      const isBackspaceOrDelete = e.keyCode === 46 || e.keyCode === 8

      if (
        isEsc &&
        !(this.renderType === 'weather_v2' || this.renderType === 'weather_channel_v2')
      ) {
        this._hideForm()
        return
      }

      if (!isAlphaNumbericKey && !isPunctuation && !isNumber && !isBackspaceOrDelete) {
        return
      }
      const value = this.input?.value || this.homeLocationInput?.value
      const id = this.input?.value ? this.input?.id : this.homeLocationInput?.id

      const options = {
        method: 'GET'
      }

      fetch(`${this.suggestionsURL}&query=${encodeURIComponent(value)}`, options)
        .then(response => response.json())
        .then(data => {
          this._renderSuggestions(data, id)
        })
        .catch(err => console.error(err))
    }

    suggestionClickHandler(e) {
      e.preventDefault()
      const item = e.target
      if (!item.classList.contains(CLASS_SUGGESTION_ITEM)) {
        return
      }
      const { woeid } = item.dataset
      const value = this.input?.value || this.homeLocationInput?.value
      let isCookie = this.hasCookie
      if (this.isChangeHome) {
        isCookie = false
        // this.isChangeHome = !this.isChangeHome
      }
      const body = { woeid, query: value }
      if (newWeatherRenderTypes.includes(this.renderType)) {
        body.noCookie = !isCookie ? '' : 'true'
      }

      const ylk = {
        ...this.ylk,
        slk: item.textContent,
        elmt: 'add-city',
        itc: 1,
        elm: 'setting',
        sec: 'weather'
      }
      rapidReady(rapid => {
        rapid.beaconClick(ylk.sec, ylk.slk, ylk.itc, ylk)
      })
      this.fetch(body, true, true)
    }

    get hasCookie() {
      return document.cookie.includes('weathergeo')
    }

    get isMobileView() {
      return !!this.mLocationModal
    }

    toggleClickHandler(e) {
      this._sendRapidClickOnToggle(e)
      this.elem.classList.toggle(CLASS_HIDE_FORM)
      this.form.classList.toggle(CLASS_HIDE_FORM)
      this.locationFormWrapper?.classList.toggle('w-0')

      if (newWeatherRenderTypes.includes(this.renderType)) {
        this.heading.classList.toggle(CLASS_HIDDEN)
        this.toggle.forEach(v => v?.classList.toggle(CLASS_HIDDEN))

        if (this.isChangeHome) {
          this.setDoneLabel()
        }
      }

      if (this.renderType === 'channel') {
        this.locationFormContainer.classList.add(CLASS_WEATHER_LOCATION_TOGGLE)
        this.locationFormHeadline.classList.add(CLASS_HIDE_HEADLINE)
      }
      this.input?.focus()
    }

    forecastSwitcherHandler(selectedForecast) {
      const isHourly = selectedForecast.classList.contains('hourly')
      const ylk = {
        ...this.ylk,
        slk: isHourly ? 'hourly' : '10 days',
        itc: 1,
        elm: 'tab'
      }

      if (!selectedForecast.classList.contains(CLASS_SELECTED)) {
        this.hourly.classList.toggle(CLASS_SELECTED)
        this.daily.classList.toggle(CLASS_SELECTED)
        this.hourlyForecast.classList.toggle(CLASS_SELECTED)
        this.dailyForecast.classList.toggle(CLASS_SELECTED)
        this.initialiseCarousel(this.elem, CAROUSEL_CONFIG, `.${selectedForecast.dataset.forecast}`)
        rapidReady(rapid => {
          rapid.beaconClick(ylk.sec, ylk.slk, ylk.itc, ylk)
        })
      }
    }

    tempFormatSwitchHandler(tempFormat) {
      const isCelsius = tempFormat.classList.contains('celsius')
      const ylk = {
        ...this.ylk,
        slk: isCelsius ? 'metric' : 'imperial',
        itc: 1,
        elm: 'icon'
      }

      if (!tempFormat.classList.contains(CLASS_SELECTED)) {
        this.setLocalStorage(LOCAL_STORAGE_TEMPERATURE_FORMAT, tempFormat.dataset.tempformat)
        this.setTempFormat(tempFormat.dataset.tempformat)
        rapidReady(rapid => {
          rapid.beaconClick(ylk.sec, ylk.slk, ylk.itc, ylk)
        })
      }
    }

    setTempFormat(temperatureFormat) {
      if (temperatureFormat === 'c') {
        document.body.classList.add(CLASS_UNIT_C)
        document.body.classList.remove(CLASS_UNIT_F)
        if (this.celsiusBtn) {
          this.celsiusBtn.setAttribute('aria-pressed', true)
        }
        if (this.fahrenheitBtn) {
          this.fahrenheitBtn.setAttribute('aria-pressed', false)
        }
      } else {
        document.body.classList.add(CLASS_UNIT_F)
        document.body.classList.remove(CLASS_UNIT_C)
        if (this.celsiusBtn) {
          this.celsiusBtn.setAttribute('aria-pressed', false)
        }
        if (this.fahrenheitBtn) {
          this.fahrenheitBtn.setAttribute('aria-pressed', true)
        }
      }
    }

    addWeatherChannelEventListeners() {
      this.observations = this.elem.querySelector(SELECTOR_OBSERVATIONS)
      this.description = this.elem.querySelector(SELECTOR_DESCRIPTION)
      this.hourly = this.elem.querySelector(SELECTOR_HOURLY)
      this.daily = this.elem.querySelector(SELECTOR_DAILY)
      this.hourlyForecast = this.elem.querySelector(SELECTOR_HOURLY_FORECAST)
      this.dailyForecast = this.elem.querySelector(SELECTOR_DAILY_FORECAST)
      this.dailyForecastArrows = this.dailyForecast.querySelector(SELECTOR_GLIDE_ARROWS)
      const forecastSwitcher = this.elem.querySelectorAll(SELECTOR_FORECAST)

      this._showLocationToggleable()

      forecastSwitcher.forEach(forecast => {
        forecast.addEventListener('click', event => {
          this.forecastSwitcherHandler(forecast)
        })
      })

      this.initialiseCarousel(this.elem, CAROUSEL_CONFIG, SELECTOR_HOURLY_FORECAST)
      this.tempFormatSwitcher = this.elem.querySelectorAll(SELECTOR_TEMP_FORMAT)
      this.tempFormatSwitcher.forEach(tempFormat => {
        tempFormat.addEventListener('click', event => {
          this.tempFormatSwitchHandler(tempFormat)
        })
      })
    }

    // PRIVATE METHODS
    _sendRapidClickOnToggle(e) {
      const { currentTarget } = e
      const isChangeLocationToggle = this.toggle.find(v => v === currentTarget)
      // Send Rapid Beacon
      const ylk = {
        ...this.ylk,
        slk: currentTarget.textContent,
        elm: isChangeLocationToggle ? 'location' : 'setting',
        elmt: 'edit-city',
        itc: 1,
        tar: window.location.host,
        sec: 'weather'
      }
      rapidReady(rapid => {
        rapid.beaconClick(ylk.sec, ylk.slk, 1, ylk)
      })
    }

    _getYlk() {
      const ylkData = this.elem.getAttribute('data-ylk')
      if (!ylkData) {
        return {}
      }
      return ylkData.split(';').reduce((obj, keyValue) => {
        const [key, value] = keyValue.split(':')
        obj[key] = value
        return obj
      }, {})
    }

    _renderSuggestions(suggestions, id) {
      this._modifyInputStyling()
      if (this.suggestionsContainer) {
        this.suggestionsContainer.innerHTML = ''
      }
      const suggestionsList = suggestionsRenderer(suggestions)
      if (id && id === SELECTOR_LOCATION_HOME_INPUT && suggestionsList) {
        this.homeSuggestionContainer.innerHTML = ''
        this.homeSuggestionContainer.appendChild(suggestionsList)
        return
      }
      if (suggestionsList) {
        this.suggestionsContainer.appendChild(suggestionsList)
      }
    }

    _modifyInputStyling() {
      if (newWeatherRenderTypes.includes(this.renderType)) {
        if (!this.input?.value) {
          this.form.style.borderRadius = '32px'
          this.form.style.borderBottom = ''
          if (this.isMobileView) {
            this.mHomeLocationForm.style.borderRadius = '20px 20px 0 0'
            this.mHomeLocationForm.style.borderBottom = '1px solid #C7CDD2'
            this.homeSuggestionContainer.style.borderBottomLeftRadius = '20px'
            this.homeSuggestionContainer.style.borderBottomRightRadius = '20px'
          }
          return
        }
        this.form.style.borderRadius = '20px 20px 0 0'
        this.form.style.borderBottom = '1px solid #C7CDD2'
        this.suggestionsContainer.style.borderBottomLeftRadius = '20px'
        this.suggestionsContainer.style.borderBottomRightRadius = '20px'
      }
    }

    _suggestionContainerHandler(e) {
      const { target } = e
      if (!target.classList.contains('location-form__suggestions--inner')) {
        if (this.suggestionsContainer) {
          this.suggestionsContainer.innerHTML = ''
        }
        if (this.homeSuggestionContainer) {
          this.homeSuggestionContainer.innerHTML = ''
        }
      }
      this.form.style.borderRadius = '32px'
      this.form.style.borderBottom = ''
      this.mHomeLocationForm ? (this.mHomeLocationForm.style.borderRadius = '32px') : ''
      this.mHomeLocationForm ? (this.mHomeLocationForm.style.borderBottom = '') : ''
    }

    _rightRailHandler(e) {
      const { target } = e
      const classToAvoid = [
        'location-form-flex__form__input',
        'location-form',
        'location-form-flex__form',
        'location-form-flex__form__search-btn',
        'location-form-flex__toggleable__label',
        'location-form-flex__toggleable__icon',
        'location-form-flex__toggleable'
      ]
      const textToAvoid = ['Set your location', 'Change']
      if (
        this.hasCookie &&
        (this.renderType === 'right-rail' || this.enableClickOutside) &&
        !textToAvoid.includes(target.textContent) &&
        !classToAvoid.some(v => target.classList.contains(v))
      ) {
        this.showHeading()
        this.showLocationToggleer()
        this._hideForm()
      }
    }

    _hideForm(response) {
      if (this.input) {
        this.input.value = ''
      }

      if (this.homeLocationInput && this.isChangeHome && response) {
        const { location: { display_name = '', state = '' } = {} } = response
        this.homeLocationInput.value = `${display_name}, ${state}`
      }
      if (this.suggestionsContainer) {
        this.suggestionsContainer.innerHTML = ''
      }
      if (this.homeSuggestionContainer) {
        this.homeSuggestionContainer.innerHTML = ''
      }
      this.elem.classList.add(CLASS_HIDE_FORM)
      // hide the input field
      this.form?.classList.add(CLASS_HIDE_FORM)
      this.locationFormWrapper?.classList.add('w-0')
      if (
        this.isMobileView &&
        (this.renderType === 'weather_v2' || this.renderType === 'weather_channel_v2')
      ) {
        this.form.classList.remove(CLASS_HIDE_FORM)
        this.locationFormWrapper?.classList.remove('w-0')
      }
      if (this.locationFormHeadline) {
        this.locationFormHeadline.classList.add(CLASS_HIDE_HEADLINE)
      }
    }

    _showLocationToggleable() {
      this.locationContainer?.classList.add(CLASS_SHOW_LOCATION)
    }

    _renderLocationPills(response) {
      this.locationController.renderLocationPills(this.locationPillListContainer, this, response)
    }

    _renderWeatherDetails(response) {
      if (!this.renderType) return
      this.detailsContainer.innerHTML = ''
      let details
      switch (this.renderType) {
        case 'minimal':
          details = detailsMinimalRenderer(response)
          break
        case 'banner':
          details = detailsBannerRenderer(response)
          break
        case 'channel':
          details = detailsChannelRenderer(response)
          break
        case 'weather_v2':
          this.handleDetailsBottomNavV2(response)
          details = weatherDetailsV2(response)
          break
        case 'weather_channel_v2':
          details = weatherHubV2(response)
          break
        case 'right-rail':
          this.showHeading()
          this.showLocationToggleer()
          details = rightRailV2(response)
          break
        default:
          details = detailsRenderer(response)
          break
      }

      this.detailsContainer.appendChild(details)
      if (this.renderType === 'channel' || this.renderType === 'weather_channel_v2') {
        this.initWeatherChannel()
      }
    }

    handleDetailsBottomNavV2(response) {
      const detailsBottomNav = weatherDetailsBottomNavV2(response)
      this.elem.querySelector(`${SELECTOR_CARD}__bottom-nav`).remove()
      this.weatherCard.appendChild(detailsBottomNav)
      this.handleFallbackForV2(response)
    }

    handleFallbackForV2({ weather = {} }) {
      if (!weather?.daily?.length) {
        this.separator.classList.add('fallback')
        this.bottomNav.classList.add('fallback')
        this.bottomNav.firstChild.classList.add('display-hidden')
      } else {
        this.weatherCard.classList.remove('fallback')
        this.separator.classList.remove('fallback')
        this.bottomNav.firstChild.classList.remove('display-hidden')
        this.bottomNav.classList.remove('fallback')
      }
    }

    _updateLocation(response) {
      const { location } = response
      if (this.locationLbl) {
        if (this.renderType === 'minimal' && location.display_name) {
          const toggleParent = this.toggle[0].parentNode
          toggleParent.removeChild(this.toggle[0])
          const locationElm = minimalLocationRenderer(response)
          toggleParent.appendChild(locationElm)
          this.locationLbl = locationElm.querySelector('.location')
        } else {
          let displayLocation = `${location.display_name}`
          if (location?.country_code === 'US' && !newWeatherRenderTypes.includes(this.renderType)) {
            displayLocation += `, ${location.state}`
          }
          if (this.changeLocLbl && this.renderType !== 'right-rail') {
            this.changeLocLbl.textContent = this.homeLocationLbl
          }
          this.locationLbl.textContent = `${displayLocation}` || 'Location Not Found'
          if (this.locationToggleLbl) {
            this.locationToggleLbl.textContent = this.locationToggleLbl.dataset.text || ''
          }
        }
      }
    }
  }

  return WeatherModule
})()
