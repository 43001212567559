const weather_icons = require('../../../lib/assets/weather_icons_new');
const el = require('../../../lib/utils/jsx_component');
const unitSwitcher = require('./unit_switcher');
const forecast = require('./forecast');
const WEATHER_DIRECTION_MAPPING = {
  0: 'N',
  1: 'NNE',
  2: 'NE',
  3: 'ENE',
  4: 'E',
  5: 'ESE',
  6: 'SE',
  7: 'SSE',
  8: 'S',
  9: 'SSW',
  10: 'SW',
  11: 'WSW',
  12: 'W',
  13: 'WNW',
  14: 'NW',
  15: 'NNW',
  16: 'N'
};
const convertToKmPerHr = windSpeed => Math.round(windSpeed * 1.609344);
const defaultHourly = new Array(24).fill(true).map((_, i) => ({
  icon_code: 48,
  isDefaultTime: true,
  pop: 0,
  temperature: 0,
  time: i + 1
}));
const defaultDaily = new Array(10).fill({
  date: Date.now() / 1000,
  icon_code: 48,
  max_temp: 0,
  min_temp: 0,
  pop: 0
});
module.exports = ({
  weather = {},
  location = {},
  isAccuWeather
}) => {
  const {
    daily = [],
    current = {},
    hourly = []
  } = weather;
  const [today = {}] = daily;
  const {
    sunrise = 0,
    sunset = 0
  } = today;
  const {
    barometric_pressure = 0,
    condition_code,
    description
  } = current;
  const iconUrl = `https://s.yimg.com/cv/apiv2/aolfp/images/weather/large/${weather_icons[condition_code]}.svg`;
  return el("div", {
    className: "weather-details-and-forecast"
  }, el("div", {
    className: "xxs-padding"
  }, el("div", {
    className: "weather-details v2"
  }, condition_code ? el("div", {
    className: "weather-details__basic"
  }, el("div", {
    className: "weather-details__current"
  }, condition_code ? el("img", {
    src: iconUrl,
    alt: description || 'weather icon',
    className: "weather-icon v2"
  }) : '', el("div", {
    className: "weather-details__current__desc"
  }, current.description || '')), el("div", {
    className: "weather-details__today"
  }, el("div", {
    className: "weather-details__today__temp"
  }, unitSwitcher(current.current_temp || 0)), el("div", {
    className: "weather-details__today__details"
  }, el("div", {
    className: "high-temp"
  }, el("svg", {
    "aria-label": "highest temperature",
    width: "14",
    height: "14",
    viewBox: "0 0 14 14",
    fill: "none"
  }, el("path", {
    d: weather_icons['max-temp']
  })), unitSwitcher(today.max_temp || 0)), el("div", {
    className: "low-temp"
  }, el("svg", {
    "aria-label": "lowest temperature",
    width: "14",
    height: "14",
    viewBox: "0 0 14 14",
    fill: "none"
  }, el("path", {
    d: weather_icons['min-temp']
  })), unitSwitcher(today.min_temp || 0)), el("div", {
    className: "humidity"
  }, el("svg", {
    "aria-label": "humidity",
    width: "10",
    height: "14",
    viewBox: "0 0 10 14",
    fill: "none",
    fillRule: "evenodd",
    clipRule: "evenodd"
  }, el("path", {
    d: weather_icons.humidity
  })), el("span", null, `${today.pop || 0}%`))))) : el("div", {
    className: "missing-data"
  }, el("div", {
    className: "missing-data__left"
  }, el("img", {
    src: "https://s.yimg.com/cv/apiv2/aolfp/images/weather/large/missing-data.svg",
    alt: "weather icon",
    className: "weather-icon"
  }), el("p", null, "No Data")), el("div", {
    className: "missing-data__right"
  }, el("div", {
    className: "missing-data-title"
  }, "Weather forecast not available"), el("div", {
    className: "missing-data-message"
  }, "We're working on this and should have things up and running soon."))), el("div", {
    className: "weather-details__additional-info v2"
  }, el("div", {
    className: "flex-col"
  }, sunrise ? `${Math.floor(sunrise / 3600)}:${`0${Math.floor(sunrise / 60 % 60)}`.slice(-2)}AM` : '-- AM', el("span", {
    className: "label"
  }, "Sunrise")), el("div", {
    className: "flex-col"
  }, `${current.humidity || '--'}%`, el("span", {
    className: "label"
  }, "Humidity")), el("div", {
    className: "flex-col"
  }, barometric_pressure ? barometric_pressure.toFixed(2) : '--', " in", el("span", {
    className: "label"
  }, "Pressure")), el("div", {
    className: "flex-col"
  }, current.uv_index || '--', el("span", {
    className: "label"
  }, "UV Index")), el("div", {
    className: "flex-col"
  }, sunset ? `${Math.floor(sunset / 3600) - 12}:${`0${Math.floor(sunset / 60 % 60)}`.slice(-2)}PM` : '-- PM', el("span", {
    className: "label"
  }, "Sunset")), el("div", {
    className: "flex-col"
  }, unitSwitcher(current.windSpeed || '--', current.windSpeed ? convertToKmPerHr(current.windSpeed || 0) : '--', 'mph', 'km/h'), el("span", {
    className: "label"
  }, "Wind")), el("div", {
    className: "flex-col"
  }, current.dew_point ? unitSwitcher(current.dew_point) : '--°', el("span", {
    className: "label"
  }, "Dew Point")), el("div", {
    className: "flex-col"
  }, current.visibility ? Math.round(current.visibility) : '--', " mi", el("span", {
    className: "label"
  }, "Visibility"))), el("div", {
    className: "temp-format-switcher"
  }, isAccuWeather ? el("div", null, el("img", {
    alt: "AccuWeather",
    src: "https://s.yimg.com/cv/apiv2/aolfp/images/weather/accuweather.svg",
    width: "70",
    height: "10"
  })) : el("div", null), el("div", {
    className: "temp-format-wrapper"
  }, el("button", {
    type: "button",
    className: "tempFormat fahrenheit",
    "data-tempFormat": "f",
    "data-rapid": "true",
    "data-ylk": "slk:imperial;elm:icon;itc:1;"
  }, "\xB0F"), el("button", {
    type: "button",
    className: "tempFormat celsius",
    "data-tempFormat": "c",
    "data-rapid": "true",
    "data-ylk": "slk:metric;elm:icon;itc:1;"
  }, "\xB0C"))))), el("div", {
    className: "separator-wrapper"
  }, el("hr", null)), el("div", {
    className: "forecast-wrapper"
  }, el("div", {
    className: "forecast-switcher-wrapper"
  }, el("div", {
    className: "forecast-switcher"
  }, el("button", {
    type: "button",
    className: "forecast hourly selected",
    "data-forecast": "hourlyForecast",
    "data-rapid": "true",
    "data-ylk": "slk:hourly;elm:tab;itc:1;"
  }, "Hourly"), el("button", {
    type: "button",
    className: "forecast daily",
    "data-forecast": "dailyForecast",
    "data-rapid": "true",
    "data-ylk": "slk:10 days;elm:tab;itc:1;"
  }, "10 Days"))), forecast('hourly-forecast', hourly.length ? hourly : defaultHourly, location), forecast('daily-forecast', daily.length ? daily : defaultDaily)), el("div", {
    className: "temp-format-switcher"
  }, isAccuWeather ? el("div", null, el("img", {
    alt: "AccuWeather",
    src: "https://s.yimg.com/cv/apiv2/aolfp/images/weather/accuweather.svg",
    width: "70",
    height: "10"
  })) : el("div", null), el("div", {
    className: "temp-format-wrapper"
  }, el("button", {
    type: "button",
    className: "tempFormat fahrenheit",
    "data-tempFormat": "f",
    "data-rapid": "true",
    "data-ylk": "slk:imperial;elm:icon;itc:1;"
  }, "\xB0F"), el("button", {
    type: "button",
    className: "tempFormat celsius",
    "data-tempFormat": "c",
    "data-rapid": "true",
    "data-ylk": "slk:metric;elm:icon;itc:1;"
  }, "\xB0C"))));
};